import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/main-banner.png";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import axios from "axios";
import Loading from "../../components/Loading ";
import { useDispatch, useSelector } from "react-redux";
import { ADD } from "../../Redux/actions/CartAction";
import { Rating } from "react-simple-star-rating";
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProductReview from "../../UI/ProductReview";
import HeaderImage2 from "../../images/banner-side -image.png";

const SingleProduct = () => {
  const [count, setCount] = useState(1);
  const [product, setProduct] = useState(null);
  const { id } = useParams();
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const dispatch = useDispatch();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const userId = useSelector((state) => state.auth.userId);
  const userDetails = useSelector((state) => state.auth.user);

  const CartNotification = () => toast.success("Product Has Been Updated");
  const handleRating = (rate) => {
    setRating(rate);
  };
  const isUserLoggedIn = !!userId;

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://backend.bolt-corp.devhub.digtalsdesigns.com/api/single-product/${id}`
        );
        if (response.data && response.data.status === "200") {
          setProduct(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          `https://backend.bolt-corp.devhub.digtalsdesigns.com/api/product-reviews/${id}`
        );
        if (response.data) {
          setReviews(response.data.reviews);
          setAverageRating(response.data.average_rating);
        }
        console.log(response.data.reviews);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchProduct();
    fetchReviews();
  }, [id]);

  const handleSubmitReview = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://backend.bolt-corp.devhub.digtalsdesigns.com/api/product-reviews",
        {
          user_id: userId,
          product_id: product.id,
          rating: rating,
          comment: comment,
          is_approved: 0,
        }
      );
      toast.success("Review submitted successfully");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error("You have already submitted a review for this product.");
      } else {
        console.error("Error submitting review:", error);
      }
    }
  };

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value);
    setCount(isNaN(newValue) ? 0 : newValue);
  };

  const increment = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const decrement = () => {
    setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : prevCount));
  };

  const handleAddToCart = (event) => {
    for (let i = 0; i < count; i++) {
      dispatch(ADD(product));
    }
    CartNotification();
  };
  if (!product) {
    return <div>{/* <Loading /> */}</div>;
  }

  // Clean the image URL
  let cleanedImageUrl = product.imagestwo
    .replace(/["\\[\]]/g, "") // Remove unwanted characters
    .replace(/\/storage\/media/, "/storage/app/media");
  return (
    <>
      <SectionHeader
        title="Single Product"
        image={HeaderImage}
        image2={HeaderImage2}
        title2="Detail About Vendor"
        content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
      ></SectionHeader>
      <section className="product-bg">
        <Container>
          <div className="product-detail">
            <Row>
              <Col xs lg="6">
                <div className="slider-container">
                  <img src={cleanedImageUrl} alt={product.slug} />
                </div>
              </Col>
              <Col xs lg="6">
                <div className="product_detail_info">
                  <div className="product_detail_info_Heading">
                    <h3>{product.slug}</h3>
                    <h2>${product.price}</h2>
                  </div>
                  <ProductReview />
                  <p className="product_detail_info_des">
                    <div
                      dangerouslySetInnerHTML={{ __html: product.description }}
                    />
                  </p>
                  <div className="product_detail_info_quantity">
                    <p>Quantity</p>
                    <div className="quantity_btns">
                      <button onClick={decrement}>-</button>
                      <input
                        type="text"
                        id="number"
                        value={count}
                        onChange={handleChange}
                      />
                      <button onClick={increment}>+</button>
                    </div>
                  </div>
                  <div className="products-detail-shop-btn">
                    <button className="custom_btn">
                      <Link onClick={handleAddToCart}>Add To Cart</Link>
                      <ToastContainer />
                    </button>
                    <div></div>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="asdf">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12}>
                    <Nav
                      variant="pills"
                      className="product_detail_info_des_tab"
                    >
                      <Nav.Item className="main_btn2">
                        <Nav.Link eventKey="first">Description</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="main_btn2">
                        <Nav.Link eventKey="second">Reviews</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="main_btn2">
                        <Nav.Link eventKey="three">Additional Info</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Aut a quasi facilis eius eaque dignissimos veniam
                        placeat. Neque consectetur corporis fugiat nostrum et
                        distinctio sapiente perferendis exercitationem.
                        Doloribus nam temporibus magnam, dignissimos, modi
                        doloremque ullam ipsa minus soluta dolores earum
                        perspiciatis est, eum rerum beatae repellat inventore
                        nihil quas veniam veritatis voluptatem labore eius
                        sequi. Omnis, facere cumque? Perspiciatis rem quia
                        ratione iure est, cum, laborum quasi impedit tempora
                        voluptates atque, ullam ipsa at incidunt quibusdam minus
                        quidem fugit nam?
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="review_main">
                          {isUserLoggedIn ? (
                            <>
                              {reviews.length > 0 ? (
                                reviews.map((review) => (
                                  <div key={review.id}>
                                    <div className="review_top">
                                      <h4>
                                        {userDetails?.name || "Anonymous"}
                                      </h4>
                                      <p>{review.comment}</p>
                                      <Rating
                                        readonly
                                        initialValue={review.rating}
                                      />
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <p>No Reviews Yet.</p>
                              )}
                              <h3>Add A Review</h3>
                              <Row className="single_product_review">
                                <Col lg={12}>
                                  <div className="product_rating">
                                    {!userId && (
                                      <p>
                                        You need to login for writing a review.{" "}
                                        <Link to="/login" className="btn-link">
                                          Login to your account
                                        </Link>
                                      </p>
                                    )}
                                    <label>
                                      Your Rating{" "}
                                      <span className="red_star"> *</span>
                                    </label>
                                    <Rating
                                      onClick={handleRating}
                                      ratingValue={rating}
                                    />
                                  </div>
                                </Col>
                                <Col lg={12}>
                                  <div className="product_rating">
                                    <label>
                                      Write a review{" "}
                                      <span className="red_star"> *</span>
                                    </label>
                                    <textarea
                                      name="comment"
                                      rows="6"
                                      value={comment}
                                      onChange={handleCommentChange}
                                      required
                                      disabled={!isUserLoggedIn}
                                    />
                                  </div>
                                </Col>
                                <Col lg={12}>
                                  <Button
                                    variant="contained"
                                    onClick={handleSubmitReview}
                                    disabled={!isUserLoggedIn}
                                  >
                                    Submit
                                  </Button>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <p>Please log in to add a review.</p>
                          )}
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="three">
                        Lorem ipsum dolor, sit amet consectetur adipisicing
                        elit. Libero aliquid exercitationem non assumenda unde
                        porro facilis blanditiis accusantium sint architecto ad
                        maxime impedit repellat dolores veniam, eius earum ex
                        quasi? Dolorum, consequuntur quo, saepe maiores eligendi
                        blanditiis eius repellat vel aperiam ab quam. Ea,
                        consequatur. Itaque saepe rerum qui dolores.
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SingleProduct;
