import React, { useEffect, useState } from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/main-banner.png";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  sortByPrice,
  setItemsPerPage,
  selectCategory,
} from "../../Redux/actions/filterActions";
import ProductListing from "../../UI/ProductListing";
import ReactPaginate from "react-paginate";
import { useLocation } from "react-router-dom";
import HeaderImage2 from "../../images/banner-side -image.png";

const Shop = () => {
  const [categories, setCategories] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedPriceRange, setSelectedPriceRange] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const sortBy = useSelector((state) => state.shop.sortBy);
  const itemsPerPage = useSelector((state) => state.shop.itemsPerPage);
  const pageCount = Math.ceil(productsData.length / itemsPerPage);
  const selectedCategory = useSelector((state) => state.shop.selectedCategory);
  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let baseUrl =
          process.env.REACT_APP_BASE_URL ||
          "https://backend.bolt-corp.devhub.digtalsdesigns.com";
        const response = await axios.get(baseUrl + "/api/show-products");
        setProductsData(response.data);
        const uniqueCategories = Array.from(
          new Set(response.data.map((item) => item.category))
        );
        const formattedCategories = uniqueCategories.map((category, index) => ({
          id: index + 1,
          category,
        }));
        setCategories(formattedCategories);
      } catch (error) {
        console.error("Error fetching Category:", error.response);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("search");
    setSearchQuery(query || "");
  }, [location]);

  // useEffect(() => {
  //     const searchParams = new URLSearchParams(location.search);
  //     const categoryParam = searchParams.get('category');
  //     dispatch(selectCategory(categoryParam)); // Set selected category from URL parameter
  //     setSearchQuery('');
  // }, [location, dispatch]);

  const handleCategorySelect = (category) => {
    dispatch(selectCategory(category));
    setPageNumber(0);
    // setSearchQuery('');
  };

  // Function to sort shop items
  const sortedShop = () => {
    let sortedItems = [...productsData];
    if (sortBy === "asc") {
      sortedItems.sort(
        (a, b) =>
          parseFloat(a.price.replace("$", "")) -
          parseFloat(b.price.replace("$", ""))
      );
    } else if (sortBy === "desc") {
      sortedItems.sort(
        (a, b) =>
          parseFloat(b.price.replace("$", "")) -
          parseFloat(a.price.replace("$", ""))
      );
    } else if (sortBy === "A-Z") {
      sortedItems.sort((a, b) => a.slug.localeCompare(b.slug));
    } else if (sortBy === "Z-A") {
      sortedItems.sort((a, b) => b.slug.localeCompare(a.slug));
    }
    return sortedItems;
  };

  const handlePriceRangeChange = (priceRange) => {
    setSelectedPriceRange(priceRange);
  };

  // Function to filter products based on selected price range
  const filterProductsByPriceRange = (products) => {
    if (!selectedPriceRange) {
      return products;
    }
    // Filter products based on selected price range
    const [minPrice, maxPrice] = selectedPriceRange
      .split("-")
      .map((price) => parseFloat(price.slice(1)));
    return products.filter((product) => {
      const productPrice = parseFloat(product.price.replace("$", ""));
      return productPrice >= minPrice && productPrice <= maxPrice;
    });
  };

  const filterProductsByCategory = (products) => {
    if (!selectedCategory) {
      return products;
    }
    return products.filter((product) => product.category === selectedCategory);
  };

  const filterProductsBySearchQuery = (products) => {
    if (!searchQuery) {
      return products;
    }
    const searchQueryLowerCase = searchQuery.toLowerCase();
    return products.filter(
      (product) =>
        (product.slug &&
          product.slug.toLowerCase().includes(searchQueryLowerCase)) ||
        (product.category &&
          product.category.toLowerCase().includes(searchQueryLowerCase)) ||
        (product.brand_id + "").toLowerCase().includes(searchQueryLowerCase)
    );
  };

  const getItemsToDisplay = () => {
    const startIndex = pageNumber * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const sortedAndFilteredProducts = filterProductsByPriceRange(
      filterProductsByCategory(sortedShop())
    );
    const searchFilteredProducts = filterProductsBySearchQuery(
      sortedAndFilteredProducts
    );
    return searchFilteredProducts.slice(startIndex, endIndex);
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(parseInt(value));
    dispatch(setItemsPerPage(parseInt(value)));
  };

  const startItemIndex = pageNumber * itemsPerPage + 1;
  const endItemIndex = Math.min(
    (pageNumber + 1) * itemsPerPage,
    productsData.length
  );
  const totalResults = productsData.length;
  return (
    <>
      <SectionHeader
        title="SHOP"
        image={HeaderImage}
        image2={HeaderImage2}
        title2="Detail About Vendor"
        content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
      ></SectionHeader>
      <section className="shop-bg">
        <Container className="pb-5">
          <Row>
            <Col sm={3}>
              <div className="category">
                <h5 className="categories">CATEGORIES</h5>
                <Accordion flush className="shop_category">
                  {categories.map(({ id, category }) => {
                    return (
                      <Accordion.Item eventKey={id} key={id}>
                        <Accordion.Header className="category-name-color">
                          {category}
                        </Accordion.Header>
                        <Accordion.Body>
                          <button
                            className="category_btn"
                            onClick={() => handleCategorySelect(category)}
                          >
                            {category}
                          </button>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
              <div className="category">
                <h5 className="price-range">PRICE RANGE</h5>
                <ul className="price__range">
                  <li>
                    <input
                      type="checkbox"
                      name="$5-$10"
                      onChange={() => handlePriceRangeChange("$5-$10")}
                    />
                    <div className="price-span">
                      <span>$5</span>-<span>$10</span>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      name="$10-$15"
                      onChange={() => handlePriceRangeChange("$10-$15")}
                    />
                    <div className="price-span">
                      <span>$10</span>-<span>$15</span>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      name="$15-$25"
                      onChange={() => handlePriceRangeChange("$15-$25")}
                    />
                    <div className="price-span">
                      <span>$15</span>-<span>$25</span>
                    </div>
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      name="$25-$50"
                      onChange={() => handlePriceRangeChange("$25-$50")}
                    />
                    <div className="price-span">
                      <span>$25</span>-<span>$50</span>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={9}>
              {/* <div className="sort-by">
                <div className="sort-by-form-group">
                  <form className="sort-by-form">
                    <select
                      id="rating-filter"
                      onChange={(e) => dispatch(sortByPrice(e.target.value))}
                    >
                      <option value="">Default Sorting</option>
                      <option value="asc">Low Price</option>
                      <option value="desc">High Price</option>
                      <option value="A-Z">A-Z</option>
                      <option value="Z-A">Z-A</option>
                    </select>
                  </form>
                  <form className="sort-by-form-2">
                    <select
                      id="itemsPerPage"
                      onChange={(e) => handleItemsPerPageChange(e.target.value)}
                    >
                      <option value="9">Default Show</option>
                      <option value="12">12</option>
                      <option value="25">25</option>
                      <option value="45">45</option>
                      <option value="50">50</option>
                    </select>
                  </form>
                </div>
                <div className="page-show-span">
                  Showing
                  <span>
                    {startItemIndex} to {endItemIndex}
                  </span>{" "}
                  of
                  <span>{totalResults}</span> results
                </div>
              </div> */}
              <div className="pb-5">
                {getItemsToDisplay().length > 0 ? (
                  <Row>
                    <ProductListing sortedProducts={getItemsToDisplay()} />
                  </Row>
                ) : (
                  <div className="alert alert-warning" role="alert">
                    No products found.
                  </div>
                )}
              </div>
              <ReactPaginate
                nextLabel="next"
                onPageChange={handlePageChange}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="previous"
                pageclassName="page-item"
                pageLinkclassName="page-link"
                previousclassName="page-item"
                previousLinkclassName="page-link"
                nextclassName="page-item"
                nextLinkclassName="page-link"
                breakLabel="..."
                breakclassName="page-item"
                breakLinkclassName="page-link"
                containerclassName="pagination"
                activeclassName="active"
                renderOnZeroPageCount={null}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Shop;
