import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";

const Addresses = () => {
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let userInfo = JSON.parse(localStorage.getItem("user-info"));

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const response = await axios.get(
          `https://backend.bolt-corp.devhub.digtalsdesigns.com/api/latest-address/${userInfo.id}`
        );
        setAddress(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching address:", error);
        setError("Failed to fetch address. Please try again later.");
        setLoading(false);
      }
    };
    fetchAddress();
  }, [userInfo.id]);

  return (
    <div className="dash-card about-bg">
      <Card className="dash-sub-card">
        <Card.Header as="h4" className="dash-card-header">
          My Address
        </Card.Header>
        <Card.Body>
          {loading && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {address && (
            <Table striped bordered hover variant="light">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                </tr>
                <tr>
                  <td>{address.name}</td>
                  <td>
                    {address.address} <br /> {address.city} <br />{" "}
                    {address.state} <br /> {address.zip_code} <br />{" "}
                    {address.phone} <br /> {address.email}
                  </td>
                </tr>
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default Addresses;
