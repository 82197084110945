import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaUser, FaEnvelope, FaPhone } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaArrowDown, FaPen } from "react-icons/fa6";

const RequestQuote = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    Services: "",
    phone: "",
    productName: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);
  };

  return (
    <div className="request-main">
      <Container>
        <div className="my-container">
          <Row>
            <div className="request-form">
              <div className="request-border">
                <h1>Order Custom Product</h1>
              </div>
              <form className="request_form" onSubmit={handleSubmit}>
                <Row>
                  <Col lg="6">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                    </div>
                    <span className="input-group-icon">
                      <FaUser />
                    </span>
                  </Col>
                  <Col lg="6" data-aos="fade-left" data-aos-duration="1000">
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Address"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <span className="input-group-icon">
                      <FaEnvelope />
                    </span>
                  </Col>
                  <Col lg="6" data-aos="fade-right" data-aos-duration="1000">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Services"
                        name="Services"
                        value={formData.Services}
                        onChange={handleChange}
                      />
                    </div>
                    <span className="input-group-icon">
                      <FaArrowDown />
                    </span>
                  </Col>
                  <Col lg="6" data-aos="fade-left" data-aos-duration="1000">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Phone Number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <span className="input-group-icon">
                      <FaPhone />
                    </span>
                  </Col>
                  <Col lg="12" data-aos="fade-left" data-aos-duration="1000">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Product Name"
                        name="productName"
                        value={formData.productName}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                  <Col lg="12" data-aos="fade-down" data-aos-duration="1000">
                    <div className="input-group">
                      <textarea
                        rows="6"
                        className="form-control"
                        placeholder="Message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <span className="input-group-icon1">
                      <FaPen />
                    </span>
                  </Col>
                  <Col lg="12" data-aos="fade-up" data-aos-duration="1000">
                    <div className="theme-group">
                      <button type="submit" className="custom_btn">
                        <Link to="/">Submit Request</Link>
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default RequestQuote;
