import React, { useEffect, useState } from "react";
import SectionHeader from "../../components/SectionHeader";
import HeaderImage from "../../images/main-banner.png";
import HeaderImage2 from "../../images/banner-side -image.png";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import RequestQuote from "../../components/RequestQuote";
import Information from "../../components/Information";
import Loading from "../../components/Loading ";

const WizardScreen = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <SectionHeader
        title="Wizard Screen"
        image={HeaderImage}
        image2={HeaderImage2}
        title2="Detail About Vendor"
        content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard"
      ></SectionHeader>

      {/* SIDE BAR START */}
      <Container className="aling">
        <Row>
          <Col md={12}>
            <Tabs defaultActiveKey="information" id="uncontrolled-tab-example">
              <Tab eventKey="information" title="Information">
                <Information />
              </Tab>
              <Tab eventKey="request" title="Order Custom Product">
                <RequestQuote />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WizardScreen;
