import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SectionName from "./SectionName";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSettings } from "../Redux/actions/settingsActions";
import { Tabs, Tab, Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Image0 from "../images/shop-img-bg.png";
import NoImage from "../images/NoImage.jpg";
import { FaStar } from "react-icons/fa";
import axios from "axios";

const OurBrands = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [activeTab, setActiveTab] = useState("all");

  const setting = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(fetchSettings());
    AOS.init();
  }, [dispatch]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const baseUrl =
          process.env.REACT_APP_BASE_URL ||
          "https://backend.bolt-corp.devhub.digtalsdesigns.com";
        const response = await axios.get(baseUrl + "/api/show-products");
        setProducts(response.data);
        setFilteredProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    if (activeTab === "all") {
      setFilteredProducts(products);
    } else {
      setFilteredProducts(
        products.filter((product) => product.brand_id === parseInt(activeTab))
      );
    }
  }, [activeTab, products]);

  const best_seller_title = settings.find(
    (setting) => setting.name === "best_seller_title"
  );
  const tabRoutes = {
    all: "/all-products",
    4: "/CreteCast",
    5: "/ZlineKitchen",
  };
  return (
    <section>
      <div className="marquee-container brand-bg-img">
        <img className="" src={Image0} alt="" />
      </div>
      <Container>
        <Container className="my-container">
          <div className="by-brands">
            <Container>
              <Row>
                <Col md={5}>
                  <div className="title-img">
                    <SectionName title={best_seller_title?.value} />
                  </div>
                </Col>
                <Col md={5}>
                  <div className="tabs">
                    <Tabs
                      activeKey={activeTab}
                      onSelect={(k) => setActiveTab(k)}
                      id="product-tabs"
                    >
                      <Tab eventKey="all" title="All">
                        <div className="mt-3"></div>
                      </Tab>
                      <Tab eventKey="4" title="Metal Sales">
                        <div className="mt-3"></div>
                      </Tab>
                      <Tab eventKey="5" title="ZLINE KITCHEN">
                        <div className="mt-3"></div>
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
                <Col md={2}>
                  <button className="custom_btn_card">
                    <Link to={tabRoutes[activeTab]}>View All Products</Link>
                  </button>
                </Col>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-all"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabIndex="0"
                  >
                    <Slider {...setting}>
                      {filteredProducts.map(
                        ({ id, price, slug, imagestwo }) => {
                          let cleanedImageUrl = imagestwo
                            .replace(/["\\[\]]/g, "") // Remove unwanted characters
                            .replace(/\/storage\/media/, "/storage/app/media");
                          return (
                            <Card
                              key={id}
                              className="by-brands-card"
                              data-aos="zoom-in-up"
                              data-aos-duration="3000"
                              data-aos-easing="linear"
                            >
                              {imagestwo ? (
                                <Link to={/single-product/ + id}>
                                  <Card.Img
                                    className="card_img"
                                    variant="top"
                                    src={cleanedImageUrl}
                                  />
                                </Link>
                              ) : (
                                <Link to={/single-product/ + id}>
                                  <Card.Img
                                    className="card_img"
                                    variant="top"
                                    src={NoImage}
                                    alt="Product img"
                                  />
                                </Link>
                              )}
                              <Card.Body>
                                <div className="star">
                                  {[...Array(5)].map((_, i) => (
                                    <i key={i}>
                                      <FaStar />
                                    </i>
                                  ))}
                                </div>
                                <Card.Title>{slug}</Card.Title>
                                <div className="card_footer">
                                  <div className="card_price">
                                    <p className="discount_price">${price}</p>
                                  </div>
                                  <button className="custom_btn_card">
                                    <Link to={/single-product/ + id}>
                                      Shop Now
                                    </Link>{" "}
                                  </button>
                                </div>
                              </Card.Body>
                            </Card>
                          );
                        }
                      )}
                    </Slider>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
        </Container>
      </Container>
    </section>
  );
};

export default OurBrands;
